// Helper functions to manage User feature flags
import { firestore } from '@/lib/firebase';
import { UserDoc } from '@/lib/firebase/db/metaTypes';

// List each feature flag field that's stored on Users documents
const featureFlags = [
  'features__dispatchScheduleEdit',
  'features__delegateToBroker',
  'features__jobProductLabel',
] as const;

export type FeatureFlag = (typeof featureFlags)[number];

export const FEATURES = {
  DISPATCH_SCHEDULE_EDIT_MODE: 'features__dispatchScheduleEdit',
  DELEGATE_TO_BROKER: 'features__delegateToBroker',
  JOB_PRODUCT_LABEL: 'features__jobProductLabel',
} as const;

/**
 * Checks if a specific feature is enabled for a user.
 * This function determines the availability of a feature based on user-specific settings or permissions.
 * It is useful for feature flagging, allowing for conditional feature access or functionality within an application.
 *
 * @param {FeatureFlag} feature - The feature to check, identified by a unique string constant.
 * @param {UserDoc} userDoc - The user document containing feature flags or settings indicating which features are enabled for the user.
 * @returns {boolean} True if the feature is enabled for the user, false otherwise.
 */
export function getIsFeatureEnabled(
  feature: FeatureFlag,
  userDoc: UserDoc
): boolean | undefined {
  return userDoc.get(feature);
}

/**
 * Updates the user document to enable or disable a specific feature.
 *
 * @param {FeatureFlag} feature - The feature to be updated, identified by a unique string constant.
 * @param {UserDoc} userDoc - The user document where the feature flag will be updated.
 * @param {boolean} [isEnabled=true] - Specifies whether the feature should be enabled (true) or disabled (false).
 * @returns The result of the save operation, typically a promise that resolves when the update is complete.
 */
export function setIsFeatureEnabled(
  feature: FeatureFlag,
  userDoc: UserDoc,
  isEnabled: boolean = true
) {
  return firestore.save(userDoc.ref, { [feature]: isEnabled }, userDoc);
}
