// Component to display the basic Job info as a card header
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';

import JobBrokerCompanyName from '@/components/Scheduler/SchedulerMain/JobCard/JobInfo/JobBrokerCompanyName';
import JobOperatorsCount from '@/components/Scheduler/SchedulerMain/JobCard/JobInfo/JobOperatorsCount';
import JobProductLabel from '@/components/Scheduler/SchedulerMain/JobCard/JobInfo/JobProductLabel';
import JobStatusBadge from '@/components/Scheduler/SchedulerMain/JobCard/JobInfo/JobStatusBadge';

import { getJobStartDatetime } from '@/lib/firebase/db/helpers';
import { JobDoc } from '@/lib/firebase/db/metaTypes';

import {
  reactAppJobDetailsRoute,
  reactAppJobEditRoute,
  reactAppUrl,
} from '@/lib/env';
import { replaceParamsInRoute } from '@/lib/helpers/functions';

export default function JobInfo({ jobDoc }: { jobDoc: JobDoc }) {
  const jobData = jobDoc.data();

  const shouldOpenEditJobForm = jobDoc.get('overallStatus') === 'INCOMPLETE';

  const onClickJobTitle = () => {
    const jobId = jobDoc.id;
    const jobDetailsUrl = `${reactAppUrl}${replaceParamsInRoute(reactAppJobDetailsRoute, { jobId })}`;
    const editJobUrl = `${reactAppUrl}${replaceParamsInRoute(reactAppJobEditRoute, { jobId })}`;
    window.open(shouldOpenEditJobForm ? editJobUrl : jobDetailsUrl);
  };

  return (
    <div className="job-info grid h-full grid-cols-6">
      <div className="col-span-4">
        <JobStatusBadge jobDoc={jobDoc} />
        <div
          className="job-title clickable group line-clamp-2 h-12 hover:underline"
          title={
            shouldOpenEditJobForm ? 'Open Edit Job Form' : 'Open Job Details'
          }
          onClick={onClickJobTitle}
        >
          {jobData?.name}{' '}
          <ArrowTopRightOnSquareIcon className="invisible inline size-4 group-hover:visible" />
        </div>
      </div>
      <div className="job-start-time col-span-2 line-clamp-1 flex items-start justify-end text-right">
        {getJobStartDatetime(jobDoc).getTimeStr()}
      </div>
      <div className="col-span-6">
        <JobProductLabel jobDoc={jobDoc} />
      </div>
      <div className="col-span-4 mt-2 ">
        <div className="job-client-name font-bold" title="Job Client">
          {jobData?.clientName}
        </div>
        <JobBrokerCompanyName jobDoc={jobDoc} />
      </div>
      <JobOperatorsCount classNames="col-span-2" jobDoc={jobDoc} />
    </div>
  );
}
