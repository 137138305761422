// Helper functions for OperatorTrucks collection
import {
  FacetLabelKey,
  OperatorTruckIndexRecord,
} from '@isheepdog/operator-search';

import { compact, startsWith } from 'lodash';

import { firestore } from '@/lib/firebase';
import { TruckAxlesType, TruckTailgateType } from '@/lib/firebase/db/@types';
import {
  JobAssignmentDoc,
  OperatorTruckDoc,
} from '@/lib/firebase/db/metaTypes';
import {
  operatorTruckByUserIdOrEmailQuery,
  operatorTruckByUserRolesAndCompanyIdQuery,
} from '@/lib/firebase/db/queries';

import { UserRole } from '@/lib/helpers/userRoles';

// List of attributes to be shown to filter
// search results of operator search.
export const OPERATOR_SEARCH_FACETS: FacetLabelKey[] = [
  'operator.role',
  'truck.truckType',
  'truck.bodyType',
  'truck.tailgate',
  'truck.materials',
  'company.tags',
  'operator.tags',
  'truck.tags',
];

const availableRolesFacets: UserRole[] = [
  'broker',
  'operatorOwner',
  'operatorEmployee',
];

// order truck type options by number of axles
const availableTruckAxlesFacets: TruckAxlesType[] = [
  'tandem',
  'tri',
  'quad',
  'quint',
  'centipede',
  'trailer',
];

const availableTruckTailgateFacets: TruckTailgateType[] = [
  'standardGate',
  'highLift',
  'sideSwing',
];

// Attributes values available to filter search results,
// override the list of values provided by search engine.
export function getDefaultOperatorSearchFacetValues({
  operatorTagsFacetValues = [],
  companyTagsFacetValues = [],
  truckTagsFacetValues = [],
}: {
  operatorTagsFacetValues?: string[];
  companyTagsFacetValues?: string[];
  truckTagsFacetValues?: string[];
} = {}): Partial<Record<FacetLabelKey, string[]>> {
  return {
    'operator.role': availableRolesFacets,
    'truck.truckType': availableTruckAxlesFacets,
    'truck.tailgate': availableTruckTailgateFacets,
    'operator.tags': [...operatorTagsFacetValues],
    'company.tags': [...companyTagsFacetValues],
    'truck.tags': [...truckTagsFacetValues],
  };
}

/**
 * Retrieves operator truck documents based on job assignments.
 * @param {JobAssignmentDoc[]} jobAssignments - An array of job assignment documents from which operator information will be extracted.
 * @returns {Promise<OperatorTruckDoc[]>} A promise that resolves to an array of operator truck documents corresponding to the job assignments.
 */
export async function getOperatorTrucksFromJobAssignments(
  jobAssignments: JobAssignmentDoc[]
): Promise<OperatorTruckDoc[]> {
  const operatorTrucks = await Promise.all(
    jobAssignments.map(async (jobAssignmentDoc) => {
      const userId = jobAssignmentDoc.get('receiver.user.id');
      const email = jobAssignmentDoc.get('receiver.user.email');
      const querySnapshot = await firestore.getDocs(
        operatorTruckByUserIdOrEmailQuery({ userId, email })
      );
      const [operatorTruckDoc] = querySnapshot.docs;
      return operatorTruckDoc;
    })
  );
  return compact(operatorTrucks);
}

/**
 * Checks if the user id string stored in OperatorTruck document starts
 * as "Invite/" meaning it was created from an Invite document.
 * @param {object} operatorTruckDoc
 * @returns {boolean}
 */
export function getIsFauxOperatorTruck(
  operatorTruckDoc: OperatorTruckDoc | OperatorTruckIndexRecord
) {
  const userId =
    'get' in operatorTruckDoc
      ? operatorTruckDoc.get('operator.userId')
      : operatorTruckDoc.operator.userId;
  return startsWith(userId, `Invite/`);
}

/**
 * Parse the OperatorTruck document from database to be used
 * in Job Form edit when loading assigned operators and maintain
 * retro-compatibility with old User document load behavior.
 */
export function parseOperatorTruckForOperatorSearch(
  operatorTruckDoc: OperatorTruckDoc
): OperatorTruckIndexRecord {
  const operatorTruckData =
    operatorTruckDoc.data() as unknown as OperatorTruckIndexRecord;
  return {
    ...operatorTruckData,
    // objectID field is necessary to filter selected operators.
    objectID: operatorTruckDoc.id,
  };
}

/**
 * Retrieves the OperatorTruck from owner or broker information based
 * on an employee's operator truck hit object. If no owner or broker
 * is found, it logs an error and returns the input object.
 *
 * @async
 * @function getOperatorTruckOwnerFromEmployee
 * @param {Object} employeeOperatorTruckHit - The employee's operator truck object.
 * @returns {Promise<Object>} An object containing the owner or broker's truck information with optional ranking info,
 *                                 or the original employeeOperatorTruckHit if no owner or broker is found, or null if the input is not provided.
 */
export async function getOperatorTruckOwnerFromEmployee(
  employeeOperatorTruckHit: OperatorTruckIndexRecord & {
    isOnQuickPick?: boolean;
  },
  { broker = true, owner = true } = {}
): Promise<OperatorTruckIndexRecord | undefined | null> {
  const companyId = employeeOperatorTruckHit.company.companyId;
  if (!companyId || employeeOperatorTruckHit.isOnQuickPick) {
    return;
  }
  const rolesToSearch: (UserRole | false)[] = [
    owner && 'operatorOwner',
    broker && 'broker',
  ];
  const searchRoles = compact(rolesToSearch);
  const ownerOperatorTruckQuerySnap = await firestore.getDocs(
    operatorTruckByUserRolesAndCompanyIdQuery({
      userRoles: searchRoles,
      companyId,
    })
  );
  const [ownerOperatorTruck] = ownerOperatorTruckQuerySnap.docs;
  if (!ownerOperatorTruck) {
    // In the case that no superior can be found for given employee
    // return the same operatorTruck
    return null;
  }
  return {
    ...parseOperatorTruckForOperatorSearch(ownerOperatorTruck),
    _rankingInfo: employeeOperatorTruckHit._rankingInfo,
    _matchingResults: employeeOperatorTruckHit._matchingResults,
  };
}
