import React from 'react';

import {
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  HandThumbDownIcon,
  NoSymbolIcon,
  PencilSquareIcon,
  StarIcon,
} from '@heroicons/react/16/solid';
import clsx from 'clsx';

import { upperCase } from 'lodash';

import { JobOverallStatus } from '@/lib/firebase/db/@types';
import {
  getJobRequiresAttention,
  getRequiresAttentionReasonString,
} from '@/lib/firebase/db/helpers';
import { JobDoc } from '@/lib/firebase/db/metaTypes';

export default function JobStatusBadge({ jobDoc }: { jobDoc: JobDoc }) {
  const jobData = jobDoc.data();

  const iconByStatus: Record<JobOverallStatus, React.FunctionComponent> = {
    INCOMPLETE: ExclamationCircleIcon,
    NEW: StarIcon,
    APPROVED: CheckCircleIcon,
    CANCELLED: NoSymbolIcon,
    IN_PROGRESS: ClockIcon,
    DECLINED: HandThumbDownIcon,
    IN_DISPUTE: ExclamationCircleIcon,
    IN_REVIEW: PencilSquareIcon,
  };

  const badgeTypeByStatus: Record<JobOverallStatus, string> = {
    INCOMPLETE: 'badge-primary',
    NEW: 'badge-info',
    APPROVED: 'badge-success',
    CANCELLED: 'badge-warning',
    IN_PROGRESS: 'badge-info',
    DECLINED: 'badge-error',
    IN_DISPUTE: 'badge-error',
    IN_REVIEW: 'bg-primary-dark border-none text-base-100',
  };

  const overallStatus = jobData?.overallStatus;
  const Icon = !!overallStatus ? iconByStatus[overallStatus] : StarIcon;
  const badgeType = !!overallStatus
    ? badgeTypeByStatus[overallStatus]
    : 'badge-info';
  const doesJobRequiresAttention = getJobRequiresAttention(jobDoc);

  return (
    <div
      className={clsx(
        'job-status-badge badge absolute left-0 top-0 gap-1 rounded-none rounded-br-lg  text-xs',
        badgeType,
        doesJobRequiresAttention ? 'rounded-tl-2xl' : 'rounded-tl-xl'
      )}
    >
      <Icon className="size-3" />
      <div
        className={clsx(
          doesJobRequiresAttention &&
            'tooltip tooltip-right before:max-w-64 before:translate-y-[-15%]'
        )}
        data-tip={
          'This job requires attention, ' +
          getRequiresAttentionReasonString(jobDoc)
        }
      >
        {upperCase(overallStatus)}
      </div>
    </div>
  );
}
