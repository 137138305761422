'use client';

import SchedulerDays from '@/components/Scheduler/SchedulerDays';
import SchedulerHeader from '@/components/Scheduler/SchedulerHeader';
import SchedulerMain from '@/components/Scheduler/SchedulerMain';

export default function SchedulerDashboard() {
  return (
    <div className="scheduler-dashboard grid h-full grid-rows-[repeat(14,minmax(0,1fr))] xl:grid-rows-12">
      <div className="row-span-1">
        <SchedulerHeader />
      </div>
      <div className="row-span-2 xl:row-span-1">
        <SchedulerDays />
      </div>
      <div className="row-span-12 xl:row-span-10">
        <SchedulerMain />
      </div>
    </div>
  );
}
