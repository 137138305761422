// Scheduler Days selector main component
import BlockSchedulerOptionsOverlay from '@/components/Scheduler/BlockSchedulerOptionsOverlay';
import SchedulerDaySwitch from '@/components/Scheduler/SchedulerDays/SchedulerDaySwitch';
import SchedulerOptions from '@/components/Scheduler/SchedulerDays/SchedulerOptions';

export default function SchedulerDays() {
  return (
    <div className="scheduler-days relative flex h-full flex-col justify-between border-b-0 border-base-200 md:px-5 xl:flex-row xl:border-b-2">
      {/* Add overlay to prevent switching selected date or filters while a Job is selected or changes are not saved*/}
      <BlockSchedulerOptionsOverlay />
      <SchedulerDaySwitch />
      <SchedulerOptions />
    </div>
  );
}
