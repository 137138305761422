// Sub-types of a Company Truck document

export type TruckAxlesType =
  | 'tandem'
  | 'tri'
  | 'quad'
  | 'quint'
  | 'centipede'
  | 'trailer';

export type TruckTailgateType = 'standardGate' | 'highLift' | 'sideSwing';

export const truckMaterials = [
  'asphalt',
  'dirt',
  'aggregates',
  'ripRapDemo',
  'class2+',
] as const;
export type TruckMaterial = (typeof truckMaterials)[number];
